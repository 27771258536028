<template>
    <div class="p-grid" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<DataTable :value="companies" sortField="company_id" :sortOrder="-1" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="company_id" :rowHover="true" 
                        :filters="filters1" :loading="loading1">
					<template #header>
						<div class="table-header">
							List of Customers
							<Button label="New" icon="pi pi-plus" class="p-button-success p-input-icon-left p-mr-2 p-mb*2 m-auto-left" @click="openNewCompanyModal" />
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters1['global']" placeholder="Global Search" />
							</span>	
						</div>
					</template>
					
					<template #loading>
						Loading customers data. Please wait.
					</template>
					<Column field="company_id" header="ID" :sortable="true" sortField="company_id">
						<template #body="slotProps" index="company_id">
							{{slotProps.data.company_id}}
						</template>
					</Column>
					<Column field="company_name" header="Name" :sortable="true" sortField="company_name">
						<template #body="slotProps" index="company_name">
							{{slotProps.data.company_name}}
						</template>
					</Column>
					<Column header="Address" :sortable="true" sortField="company_address" filterField="company_address">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.company_address}}</span>
						</template>
					</Column>
					<Column header="Email" :sortable="true" sortField="company_email" filterField="company_email">
						<template #body="slotProps">
							<span v-tooltip="slotProps.data.company_email" style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.company_email}}</span>
						</template>
					</Column>
					<Column field="company_total_invoice" header="Total Invoice" :sortable="true" sortField="company_total_invoice">
						<template #body="slotProps">
							<span>{{slotProps.data.company_total_invoice}}</span>
						</template>
					</Column>
					<Column field="company_successful_invoice" header="Successful Invoice" :sortable="true" sortField="company_successful_invoice">
						<template #body="slotProps">
							<span>{{slotProps.data.company_successful_invoice}}</span>
						</template>
					</Column>
					<Column field="company_admin" header="Representative" :sortable="true" sortField="company_admin">
						<template #body="slotProps">
							<span :class="'customer-badge '">{{slotProps.data.company_admin}}</span>
						</template>
					</Column>
					<Column field="company_phone_number" header="Phone" :sortable="true" sortField="company_phone_number">
						<template #body="slotProps">
							<span :class="'customer-badge' ">{{slotProps.data.company_phone_number}}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="openModal(slotProps.data)"></Button>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="openDeleteModal(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>

                <Dialog header="Dialog" v-model:visible="displayEditModal" :style="{width: '30vw'}" :modal="true">
					<div class="p-col-12">
						<div class="card">
							<h5>Edit company information</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-6">
									<label for="company_name_new">Name</label>
									<br/>
									<InputText id="company_name_new" type="text" v-model="selectedCompany.company_name"/> 
									<!-- v-if="!displayNewModal"  -->
									<!-- <InputText id="company_name_new" type="text" v-else v-model="newCompany.company_name"/> -->
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="company_email_new">Email</label>
									<InputText id="company_email_new" type="text" v-model="selectedCompany.company_email"/>
									<!-- v-if="!displayNewModal" -->
									<!-- <InputText id="company_email_new" type="text" v-else v-model="newCompany.company_email"/> -->
								</div>
								<div class="p-field p-col-12">
									<label for="company_address_new">Address</label>
									<Textarea id="company_address_new" rows="4" v-model="selectedCompany.company_address"/>
									<!-- v-if="!displayNewModal"
									<Textarea id="company_address_new" rows="4" v-else v-model="newCompany.company_address" /> -->
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="company_admin_new">Representative</label>
									<InputText id="company_admin_new" type="text" v-model="selectedCompany.company_admin"/>
									 <!-- v-if="!displayNewModal"
									<InputText id="company_admin_new" type="text" v-else v-model="newCompany.company_admin"/> -->

								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="company_phone_number_new">Phone</label>
									<InputText id="company_phone_number_new" v-model="selectedCompany.company_phone_number"></InputText>
									 <!-- v-if="!displayNewModal"
									<InputText id="company_phone_number_new" v-else v-model="newCompany.company_phone_number"></InputText> -->

								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Save" @click="insertCompany" icon="pi pi-check" class="p-button-text"/>
						<Button label="Dismiss" @click="closeModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>

				<Dialog header="Dialog" v-model:visible="displayDeleteModal" :style="{width: '30vw'}" :modal="true">
					<p>
						Are you sure to delete?
					</p>
					<template #footer>
						<Button label="Yes" @click="deleteCompany" icon="pi pi-check" class="p-button-text"/>
						<Button label="No" @click="closeDeleteModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>

    import CompanyService from "../service/CompanyService";
	
	export default {
		data() {
			return {
                companies:[],
				componentKey: 0,
				selectedCompany: {},
				filters1: {},
				filters2: {},
				loading1: true,
				expandedRows: [],
                displayEditModal: false,
				displayDeleteModal: false,
			}
		},
		companyService: null,
        created() {
			
			this.companyService = new CompanyService();
		},
		mounted() {
			this.onMounted()
		},
		methods: {
			onMounted(){
				this.companyService.getCompaniesAll().then(data => {
					if (data.isLogged){
						this.companies = data.fetch_data
						this.loading1 = false;
					} else {
						this.$router.push('Login')
					}
				});
			},
			forceRerender(){
				this.componentKey +=1;
			},
            openModal: function(rowId){
				this.selectedCompany = rowId;
				this.displayEditModal = true;
			},
            closeModal(){
                this.displayEditModal = false;
				this.selectedCompany = {};
            },
			openDeleteModal: function(rowId){
				
				this.selectedCompany = rowId;
				this.displayDeleteModal = true;
			},
			closeDeleteModal(){
				this.displayDeleteModal = false;
				this.selectedCompany =  {};
			},
			deleteCompany(){
				this.companyService.deleteCompany(this.selectedCompany)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {					
						this.closeDeleteModal();
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			},
			openNewCompanyModal(){
				this.displayEditModal = true;
				this.selectedCompany = {}
			},
			insertCompany(){
				if(this.selectedCompany.company_id){
					this.companyService.updateCompany(this.selectedCompany)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {					
						this.closeModal();
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
				} else {
					this.companyService.insertNewCompany(this.selectedCompany)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {					
						this.closeModal();
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
        overflow:hidden;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
