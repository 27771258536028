import axios from 'axios'
axios.defaults.withCredentials = true;

export default class CompanyService {

  getCompaniesAll() {

    return axios.get('https://advantex.uz/pmdb/company2').then(res => res.data);
  }

  insertNewCompany(newCompany) {

    var data = JSON.stringify({
      "array": {
        "company_address": newCompany.company_address,
        "company_admin": newCompany.company_admin,
        "company_email": newCompany.company_email,
        "company_name": newCompany.company_name,
        "company_phone_number": newCompany.company_phone_number
      }
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/pmdb/insert_company_info',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    return axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

  }

  updateCompany(updatingCompany) {
    var data = JSON.stringify({
      "array": {
        "company_address": updatingCompany.company_address,
        "company_admin": updatingCompany.company_admin,
        "company_email": updatingCompany.company_email,
        "company_name": updatingCompany.company_name,
        "company_phone_number": updatingCompany.company_phone_number
      },
      "id": updatingCompany.company_id
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/pmdb/update_company_info',
      headers: {
        'Content-Type': 'application/json'
        // 'Cookie': 'ci_session=bf1f1a5c44a918450f745399d4012ce7641c0b21'
      },
      data: data
    };

    return axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }


  deleteCompany(deletingCompany) {
    var data = JSON.stringify({
      "id": deletingCompany.company_id
    });

    var config = {
      method: 'delete',
      url: 'https://advantex.uz/pmdb/deleteCompany',
      headers: {
        'Content-Type': 'application/json',
        // 'Cookie': 'ci_session=9793a9b4465180a90e736194a1608669d5ca6ebd'
      },
      data: data
    };

    return axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });  
  }

  getCompanyById(id) {
    return axios.get('https://advantex.uz/pmdb/get_company_info', {
      params: {
        id: id
      }
    }).then(res => res.data);
  }


}  
